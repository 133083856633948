/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-hosting_1 {
  background-image: url(../images/sprite.png);
  background-position: -236px -135px;
  width: 40px;
  height: 40px;
}
.icon-hosting_1_white {
  background-image: url(../images/sprite.png);
  background-position: -287px 0px;
  width: 40px;
  height: 40px;
}
.icon-hosting_2 {
  background-image: url(../images/sprite.png);
  background-position: -183px -47px;
  width: 46px;
  height: 40px;
}
.icon-hosting_2_white {
  background-image: url(../images/sprite.png);
  background-position: -183px -92px;
  width: 46px;
  height: 40px;
}
.icon-hosting_3 {
  background-image: url(../images/sprite.png);
  background-position: -53px -214px;
  width: 50px;
  height: 33px;
}
.icon-hosting_3_white {
  background-image: url(../images/sprite.png);
  background-position: -108px -214px;
  width: 47px;
  height: 32px;
}
.icon-moon {
  background-image: url(../images/sprite.png);
  background-position: -353px -148px;
  width: 16px;
  height: 16px;
}
.icon-moon_gray {
  background-image: url(../images/sprite.png);
  background-position: -332px -148px;
  width: 16px;
  height: 16px;
}
.icon-pay_system_1 {
  background-image: url(../images/sprite.png);
  background-position: 0px -187px;
  width: 80px;
  height: 22px;
}
.icon-pay_system_2 {
  background-image: url(../images/sprite.png);
  background-position: -332px -45px;
  width: 32px;
  height: 23px;
}
.icon-pay_system_3 {
  background-image: url(../images/sprite.png);
  background-position: -160px -214px;
  width: 50px;
  height: 23px;
}
.icon-pay_system_4 {
  background-image: url(../images/sprite.png);
  background-position: -236px -180px;
  width: 45px;
  height: 17px;
}
.icon-qualities_1 {
  background-image: url(../images/sprite.png);
  background-position: -236px -90px;
  width: 45px;
  height: 40px;
}
.icon-qualities_1_white {
  background-image: url(../images/sprite.png);
  background-position: -236px -45px;
  width: 45px;
  height: 40px;
}
.icon-qualities_2 {
  background-image: url(../images/sprite.png);
  background-position: 0px -214px;
  width: 48px;
  height: 37px;
}
.icon-qualities_2_white {
  background-image: url(../images/sprite.png);
  background-position: -112px -97px;
  width: 51px;
  height: 40px;
}
.icon-qualities_3 {
  background-image: url(../images/sprite.png);
  background-position: 0px 0px;
  width: 59px;
  height: 47px;
}
.icon-qualities_3_white {
  background-image: url(../images/sprite.png);
  background-position: -55px -142px;
  width: 50px;
  height: 40px;
}
.icon-qualities_4 {
  background-image: url(../images/sprite.png);
  background-position: -287px -135px;
  width: 40px;
  height: 40px;
}
.icon-qualities_4_white {
  background-image: url(../images/sprite.png);
  background-position: -287px -180px;
  width: 40px;
  height: 40px;
}
.icon-slider_next {
  background-image: url(../images/sprite.png);
  background-position: -332px -119px;
  width: 13px;
  height: 24px;
}
.icon-slider_prev {
  background-image: url(../images/sprite.png);
  background-position: -350px -119px;
  width: 13px;
  height: 24px;
}
.icon-sun {
  background-image: url(../images/sprite.png);
  background-position: -332px -73px;
  width: 18px;
  height: 18px;
}
.icon-sun_black {
  background-image: url(../images/sprite.png);
  background-position: -332px -96px;
  width: 18px;
  height: 18px;
}
.icon-why_me_1 {
  background-image: url(../images/sprite.png);
  background-position: -332px 0px;
  width: 37px;
  height: 40px;
}
.icon-why_me_1_white {
  background-image: url(../images/sprite.png);
  background-position: -270px -256px;
  width: 38px;
  height: 40px;
}
.icon-why_me_2 {
  background-image: url(../images/sprite.png);
  background-position: -61px -52px;
  width: 52px;
  height: 40px;
}
.icon-why_me_2_white {
  background-image: url(../images/sprite.png);
  background-position: -126px -45px;
  width: 51px;
  height: 40px;
}
.icon-why_me_3 {
  background-image: url(../images/sprite.png);
  background-position: -236px 0px;
  width: 46px;
  height: 40px;
}
.icon-why_me_3_white {
  background-image: url(../images/sprite.png);
  background-position: -183px 0px;
  width: 48px;
  height: 42px;
}
.icon-why_me_4 {
  background-image: url(../images/sprite.png);
  background-position: -90px -256px;
  width: 40px;
  height: 40px;
}
.icon-why_me_4_white {
  background-image: url(../images/sprite.png);
  background-position: -135px -256px;
  width: 40px;
  height: 40px;
}
.icon-why_me_5 {
  background-image: url(../images/sprite.png);
  background-position: -180px -256px;
  width: 40px;
  height: 40px;
}
.icon-why_me_5_white {
  background-image: url(../images/sprite.png);
  background-position: -225px -256px;
  width: 40px;
  height: 40px;
}
.icon-why_me_6 {
  background-image: url(../images/sprite.png);
  background-position: -287px -90px;
  width: 40px;
  height: 40px;
}
.icon-why_me_6_white {
  background-image: url(../images/sprite.png);
  background-position: -287px -45px;
  width: 40px;
  height: 40px;
}
.icon-why_me_7 {
  background-image: url(../images/sprite.png);
  background-position: 0px -52px;
  width: 56px;
  height: 40px;
}
.icon-why_me_7_white {
  background-image: url(../images/sprite.png);
  background-position: -64px 0px;
  width: 57px;
  height: 40px;
}
.icon-why_me_8 {
  background-image: url(../images/sprite.png);
  background-position: 0px -142px;
  width: 50px;
  height: 40px;
}
.icon-why_me_8_white {
  background-image: url(../images/sprite.png);
  background-position: -110px -142px;
  width: 50px;
  height: 40px;
}
.icon-why_me_9 {
  background-image: url(../images/sprite.png);
  background-position: -56px -97px;
  width: 51px;
  height: 40px;
}
.icon-why_me_9_white {
  background-image: url(../images/sprite.png);
  background-position: -126px 0px;
  width: 52px;
  height: 40px;
}
.icon-why_me_circle {
  background-image: url(../images/sprite.png);
  background-position: -45px -256px;
  width: 40px;
  height: 40px;
}
.icon-why_me_circle_white {
  background-image: url(../images/sprite.png);
  background-position: 0px -256px;
  width: 40px;
  height: 40px;
}
.icon-why_me_fly {
  background-image: url(../images/sprite.png);
  background-position: 0px -97px;
  width: 51px;
  height: 40px;
}
.icon-why_me_fly_white {
  background-image: url(../images/sprite.png);
  background-position: -165px -142px;
  width: 50px;
  height: 40px;
}

@media (-webkit-min-device-pixel-ratio: 2),
       (min-resolution: 192dpi) {
  .icon-hosting_1 {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-hosting_1_white {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-hosting_2 {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-hosting_2_white {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-hosting_3 {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-hosting_3_white {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-moon {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-moon_gray {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-pay_system_1 {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-pay_system_2 {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-pay_system_3 {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-pay_system_4 {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-qualities_1 {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-qualities_1_white {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-qualities_2 {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-qualities_2_white {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-qualities_3 {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-qualities_3_white {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-qualities_4 {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-qualities_4_white {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-slider_next {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-slider_prev {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-sun {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-sun_black {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_1 {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_1_white {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_2 {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_2_white {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_3 {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_3_white {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_4 {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_4_white {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_5 {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_5_white {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_6 {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_6_white {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_7 {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_7_white {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_8 {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_8_white {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_9 {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_9_white {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_circle {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_circle_white {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_fly {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
  .icon-why_me_fly_white {
    background-image: url(../images/sprite@2x.png);
    background-size: 369px 296px;
  }
}
