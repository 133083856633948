@import (less) "images/sprite.css";
@import (inline) "css/reset.css";

@opensans: 'OpenSans', Arial, Tahoma, sans-serif;
@bebas: 'Bebas', Arial, Tahoma, sans-serif;
@blue: #3699ff;
@transition: all .3s ease-out;

*,*:before,*:after{box-sizing:border-box}
.container{margin-right:auto;margin-left:auto;padding-left:15px;padding-right:15px}
@media(min-width:0){.container{width:100%}}
@media(min-width:768px){.container{width:750px}}
@media(min-width:1020px){.container{width:1000px}}
@media(min-width:1240px){.container{width:1210px}}
.clearfix:before,.clearfix:after,.container:before,.container:after{content:" ";display:table}.clearfix:after,.container:after{clear:both}

html{
	font-size: 16px;
	font-weight: 400;
}

body{
	font-family: @opensans;	
	color: #fff;
	background: #151521;
}
 
a{
	text-decoration: none; 
	color: inherit;
	&:hover{
		text-decoration: none;
	}
}

p{
	line-height: 1.5;
}

.icon{
	display: block;
}   

*, 
*:active, 
*:focus{
	outline: none !important; 
}  

.page{
	overflow: hidden; 
	display: flex;
    min-height: 100vh; 
    flex-direction: column;
    position: relative; 
}

.main-content{
	flex: 1 1 auto;
}

.floatLeft{ 
	float: left; 
} 
.floatRight{
	float: right;  
}

strong{
	font-weight: 700;
}

.btn{
	display: inline-block;
	transition: @transition;
	color: #feffff;
	font-family: @opensans;
	font-size: 20px;
  	font-weight: 600;
	padding: 14px 68px;
	text-align: center;
	line-height: 1.3;
	border: none;
	background: @blue;
	border-radius: 14px;
	&:hover{
		background: #1f5cae;
	}
	&.empty-btn{
		font-size: 16px;
		position: relative;
		z-index: 2;
		padding: 13px 36px;
		border-radius: 12px;
		background: #1f1f33;
		&:before{
			content: "";
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			position: absolute;
			z-index: -1;
			background: #2a71b8;
			background: -moz-linear-gradient(left,  #2a71b8 0%, #3b9bfe 100%);
			background: -webkit-linear-gradient(left,  #2a71b8 0%,#3b9bfe 100%);
			background: linear-gradient(to right,  #2a71b8 0%,#3b9bfe 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2a71b8', endColorstr='#3b9bfe',GradientType=1 );
			border-radius: 12px;
			transition: @transition;
			opacity: 0;
		}
		&:hover:before{
			opacity: 1;
		}
	}
	&.yellow-btn{
		padding: 14px 35px;
		background: #fea900;
		&:hover{
			background: #db8600;
		}
	}
}

button.btn{
	cursor: pointer;
	line-height: 1.1;
	font-family: @opensans;
	min-height: 45px;
	padding: 10px 35px;
}

.simple-form{
	input,
	select,
	textarea{
		display: block;
		width: 100%;
		height: 45px;
		transition: border-color .3s ease-out;
		padding: 0 16px;
		color: #fff;
		font-size: 16px;
		font-weight: 400;
		font-family: @opensans;
		border-radius: 9px;
		border: 1px solid #2e2e50;
  		background: transparent;
		&::-webkit-input-placeholder{ 
		   	color: #fff;
		    opacity: 1;
		}
		&:-moz-placeholder{ 
		   	color: #fff;
		    opacity: 1;
		}
		&::-moz-placeholder{ 
		  	color: #fff;
		    opacity: 1;
		}
		&:-ms-input-placeholder{ 
		   	color: #fff;
		    opacity: 1;
		}
		&:focus{
  			border-color: @blue;
  		}
	}
	select{
		appearance: none;
		background: url("../images/select-arrow.png") right 16px center no-repeat;   
		background-size: 14px 8px;
		padding-right: 40px;
		cursor: pointer;
		&:hover{
  			border-color: @blue;
  		}
	}
	option{
		color: #fff;
		background: #1c1c2d;
	}
	textarea{
		resize: none;
		height: 156px;
		line-height: 1.3;
		padding: 20px;
	}
	.input-block{
		position: relative;
		margin-bottom: 15px;
	}
	.input-label{
		font-weight: 300;
		line-height: 1.2;
		margin-bottom: 12px;
	}
}


// header ===================================================
header{
	position: relative;
	z-index: 40;
}


.statistics-block{
	background-color: #1f1e2e;
	padding: 7px 0;
}

.statistics-content{
	display: flex;
	align-items: center;
}

.statistics-theme{
	margin-left: auto;
	display: flex;
	align-items: center;
}

.statistics-theme-toggle{
	display: flex;
	border-radius: 13px;
  	border: solid 1px #343348;
  	flex-shrink: 0;
  	margin: 0 20px;
  	height: 26px;
  	padding: 3px;
  	label{
  		flex-shrink: 0;
  		display: flex;
  		cursor: pointer;
  	}
	input{
		display: none;
		&:checked{
			& ~ i{
				background: #3699ff;
			}
		}
	}
	i{
		transition: @transition;
		display: block;
		width: 18px;
  		height: 18px;
		border: none;
		border-radius: 50%;
		background: transparent;
	}
}

.statistics-count{
	display: flex;
	align-items: center;
	margin: 0 -15px;
	color: #8e8e8e;
	font-weight: 300;
	li{
		margin: 0 15px;
	}
	strong{
		margin-left: 5px;
	}
}

.header-content{
	display: flex;
	align-items: center;
	padding: 20px 0;
}

.dshost-logo{
	display: block;
	height: 50px;
	width: 180px;
	background: url("../images/logo-dark.png") center no-repeat;
	background-size: 100% auto;
	margin-right: auto;
	flex-shrink: 0;
}

.header-options{
	display: flex;
	align-items: center;
	padding-bottom: 11px;
}

.header-menu{
	display: flex;
	align-items: center;
	margin: 0 -12px;
	text-align: center;
	li{
		margin: 0 12px;
	}
	a{
		position: relative;
		transition: @transition;
		&:hover{
			color: @blue;
		}
	}
	.active{
		font-weight: 600;
		color: @blue;
		&:after{
			content: "";
			width: 100%;
			left: 0;
			top: 100%;
			margin-top: 3px;
			background: @blue;
			height: 3px;
			position: absolute;
			box-shadow: 0px 2px 8.3px 0.7px rgba(0, 204, 255, 0.21);
		}
	}
}

.header-enter{
	display: flex;
	align-items: center;
	margin-left: 52px;
	.divider{
		margin: 0 4px;
	}
	a{
		transition: @transition;
		&:hover{
			color: @blue;
			text-decoration: underline;
		}
	}
}

.mobile-buter{
	display: none;
}


// about-section ===============================================
.about-section{
	background: url("../images/about-bg-lines.png") center no-repeat;
	padding: 129px 0 100px;
	h2{
		margin-bottom: 8px;
	}
}

.about-content{
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.about-image{
	flex-shrink: 0;
	width: 584px;
	position: relative;
	z-index: 2;
	margin-right: -3px;
	img{
		max-width: 100%;
		height: auto;
	}
}

.about-image-bg{
	position: absolute;
	z-index: -1;
	width: 724px;
  	height: 724px;
	top: 50%;
	left: 50%;
	margin-top: -362px;
	margin-left: -362px;
	li{
		position: absolute;
		opacity: 0.1;
  		background-color: #000;
  		border-radius: 50%;
  		&:nth-of-type(1){
			width: 100%;
  			height: 100%;
		}
		&:nth-of-type(2){
			width: 636px;
  			height: 636px;
  			top: 50%;
			left: 50%;
			margin-left: -318px;
			margin-top: -318px;
		}
		&:nth-of-type(3){
			width: 546px;
  			height: 546px;
  			top: 50%;
			left: 50%;
			margin-left: -273px;
			margin-top: -273px;
		}
	}
}

.about-text{
	position: relative;
	z-index: 3;
	max-width: 445px;
	margin-right: 30px;
	padding-top: 8px;
	p{
		margin-bottom: 35px;
	}
}

h2{
	text-transform: uppercase;
	line-height: 1.06;
	font-size: 70px;
  	font-weight: bold;
  	font-family: @bebas;
  	margin-bottom: 35px;
}

.section-title{
	font-size: 24px;
  	font-weight: 600;
  	color: #a1c5ea;
  	text-transform: uppercase;
  	margin-bottom: 18px;
  	display: flex;
  	align-items: center;
  	&:before{
  		content: "";
  		margin-right: 21px;
  		display: block;
  		background: #a1c5ea;
  		width: 17px;
  		height: 2px;
  	}
  	&.long:before{
  		width: 47px;
  	}
}


// tariffs-section ===============================================
.tariffs-section{
	padding: 86px 0 28px;
}

.tariffs-top{
	margin-bottom: 34px;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	h2{
		max-width: 500px;
		margin-bottom: 0;
	}
	.btn{
		margin-bottom: 15px;
	}
}

.tariffs-list{
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;
}

.tariffs-block{
	width: ~"calc(25% - 20px)";
	margin: 0 10px 20px;
	position: relative;
	height: 276px;
	z-index: 2;
	background: #333333;
	display: block;
	border-radius: 30px;
	text-align: center;
	color: #fff;
	transition: @transition;
	&:hover{
		&:after{
			opacity: 0;
		}
		&:before{
			opacity: 1;
		}
		.image{
			filter: grayscale(0);
			opacity: 0.68;
		}
		.detail{
			background: #fea900;
			&:after{
				opacity: 1;
			}
		}
	}
	&:after{
		content: "";
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: -2;
		border-radius: 30px;
		transition: @transition;
		background: -moz-linear-gradient(left, rgba(4,49,95,0.56) 0%, rgba(4,49,95,0) 100%);
		background: -webkit-linear-gradient(left, rgba(4,49,95,0.56) 0%,rgba(4,49,95,0) 100%);
		background: linear-gradient(to right, rgba(4,49,95,0.56) 0%,rgba(4,49,95,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8f04315f', endColorstr='#0004315f',GradientType=1 );
	}
	&:before{
		content: "";
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: -1;
		border-radius: 30px;
		opacity: 0;
		transition: @transition;
		background: -moz-linear-gradient(left, rgba(54,153,255,0.56) 0%, rgba(54,153,255,0) 100%);
		background: -webkit-linear-gradient(left, rgba(54,153,255,0.56) 0%,rgba(54,153,255,0) 100%);
		background: linear-gradient(to right, rgba(54,153,255,0.56) 0%,rgba(54,153,255,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8f3699ff', endColorstr='#003699ff',GradientType=1 );
	}
	.image{
		position: absolute;
		border-radius: 30px;
		z-index: -3;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 30px;
		display: block;
		overflow: hidden;
		filter: grayscale(1);
		opacity: 0.24;
		transition: @transition;
		img{
			width: 100%;
			height: 100%;
			max-width: 100%;
			max-height: 100%;
			object-fit: cover;
			image-rendering: -webkit-optimize-contrast;
		}
	}
	.name{
		padding: 15px;
		font-size: 36px;
  		font-weight: bold;
  		font-family: @bebas;
  		height: 116px;
  		display: flex;
  		align-items: flex-end;
  		justify-content: center;
	}
	.price{
		position: relative;
		z-index: 5;
		padding: 15px;
		font-size: 20px;
		font-weight: 600;
		margin: 0 -4px;
		strong{
			font-size: 24px;
		}
		.old-price{
			margin-right: 6px;
			position: relative;
			&:after{
				content: ""; 
				left: 0;
				height: 2px;
				top: 50%;
				width: 100%;
				position: absolute;
				z-index: 2;
				background: #fff;
				margin-top: 2px;
			}
		}
	}
	.rub{
		display: inline-block;
		width: 14px;
		height: 17px;
		background: url("../images/rub-white.svg") center no-repeat;
		background-size: auto 100%;
		margin-left: 1px;
	}
	.bg{
		left: -1px;
		top: 0;
		height: 100%;
		width: ~"calc(100% + 1px)";
		position: absolute;
		z-index: -1;
		box-shadow: 0px 13px 7px 0 rgba(0, 0, 0, 0.14);
		background: @blue;
		transform: rotate(-1.8deg);
		backface-visibility: hidden;
		&:before, 
		&:after{
		   content: '';
		   position: absolute;
		   border-style: solid;
		   border-color: transparent;
		   bottom: -7px;
		}
		&:before{
			border-width: 0 4px 8px 0;
			border-right-color: @blue;
			left: 0;
		}
		&:after{
			border-width: 0 0 8px 5px;
			border-left-color: @blue;
			right: 0;
		}
	}
	.detail{
		position: relative;
		z-index: 3;
		display: inline-block;
		padding: 15px 32px;
		font-weight: 600;
		font-size: 16px;
		background: transparent;
		padding-top: 25px;
		margin-top: -11px;
		&:after{
			content: "";
			right: 0;
			bottom: -5px;
			position: absolute;
			z-index: -1;
			border-right: 140px solid #fea900;
			border-top: 5px solid transparent;
			border-bottom: 5px solid transparent;
			height: 0;
			width: 100%;
			opacity: 0;
		}
	}
	.pro{
		font-size: 16px;
  		font-weight: bold;
  		position: absolute;
  		z-index: 5;
  		top: 27px;
  		left: 0;
  		text-transform: uppercase;
  		color: #fff;
  		background: #fea900;
  		height: 28px;
  		padding: 0 12px;
  		display: flex;
  		align-items: center;
  		&:before{
		  content:"";
		  width:0;
		  height:0;
		  position:absolute;
		  z-index: -1;
		  bottom: 0;
		  left: 100%;
		  z-index:100;
		  border:2px solid transparent;
		  border-top:14px solid transparent;
		  border-bottom:14px solid #fea900;
		  border-right:10px solid transparent;
		  border-left:0 solid transparent;
		}
  		&:after{
		  content:"";
		  width:0;
		  height:0;
		  position:absolute;
		  z-index: -1;
		  top:0%; 
		  left:100%;
		  border:1px solid transparent;
		  border-top:2px solid #fea900;
		  border-bottom:14px solid transparent;
		  border-right:14px solid tranaparent;
		  border-left:10px solid #fea900;
		}
	}
}


// why-me-section ===============================================
.why-me-section{
	padding: 30px 0 40px;
}

.why-me-list{
	display: flex;
	flex-wrap: wrap;
	padding-top: 1px;
	margin: 0 -15px;
	li{
		width: 25%;
		padding: 0 15px;
		margin-bottom: 34px;
	}
	.image{
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		background: #1c1c2d;
		width: 80px;
		height: 80px;
		position: relative;
		z-index: 2;
		border-radius: 12px;
		margin-bottom: 16px;
		&:before{
	  		content: "";
	  		position: absolute;
	  		z-index: -1;
	  		border-radius: 12px;
	  		left: 0;
	  		top: 0;
	  		height: 100%;
	  		width: 100%;
	  		background: -moz-linear-gradient(left, rgba(4,49,95,0.56) 0%, rgba(7,55,105,0.56) 6%, rgba(54,153,255,0) 100%);
			background: -webkit-linear-gradient(left, rgba(4,49,95,0.56) 0%,rgba(7,55,105,0.56) 6%,rgba(54,153,255,0) 100%);
			background: linear-gradient(to right, rgba(4,49,95,0.56) 0%,rgba(7,55,105,0.56) 6%,rgba(54,153,255,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8f04315f', endColorstr='#003699ff',GradientType=1 );
	  	}
	}
	.name{
		color: @blue;
		font-family: @bebas;
		font-size: 36px;
		font-weight: bold;
		margin-bottom: 6px;
	}
	p{
		line-height: 1.63;
		color: #a1c5ea;
		max-width: 220px;
	}
	strong{
		font-weight: 600;
	}
}


// qualities-section ===============================================
.qualities-section{
	padding: 60px 0 50px;
	text-align: center;
}

.qualities-title{
	font-size: 24px;
  	font-weight: 600;
  	color: #a1c5ea;
  	text-transform: uppercase;
  	margin-bottom: 18px;
  	display: flex;
  	align-items: center;
  	justify-content: center;
  	&:before{
  		content: "";
  		margin-right: 22px;
  		display: block;
  		background: #a1c5ea;
  		width: 90px;
  		height: 2px;
  		flex-shrink: 0;
  	}
  	&:after{
  		content: "";
  		margin-left: 22px;
  		display: block;
  		background: #a1c5ea;
  		width: 90px;
  		height: 2px;
  		flex-shrink: 0;
  	}
}

.qualities-list{
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;
	li{
		width: 25%;
		padding: 0 15px;
		margin-bottom: 34px;
	}
	.image{
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		background: #1c1c2d;
		width: 80px;
		height: 80px;
		position: relative;
		z-index: 2;
		border-radius: 12px;
		margin: 0 auto 25px;
		&:before{
	  		content: "";
	  		position: absolute;
	  		z-index: -1;
	  		border-radius: 12px;
	  		left: 0;
	  		top: 0;
	  		height: 100%;
	  		width: 100%;
	  		background: -moz-linear-gradient(left, rgba(4,49,95,0.56) 0%, rgba(7,55,105,0.56) 6%, rgba(54,153,255,0) 100%);
			background: -webkit-linear-gradient(left, rgba(4,49,95,0.56) 0%,rgba(7,55,105,0.56) 6%,rgba(54,153,255,0) 100%);
			background: linear-gradient(to right, rgba(4,49,95,0.56) 0%,rgba(7,55,105,0.56) 6%,rgba(54,153,255,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8f04315f', endColorstr='#003699ff',GradientType=1 );
	  	}
	}
	.name{
		color: @blue;
		font-family: @bebas;
		font-size: 36px;
		font-weight: bold;
		margin-bottom: 13px;
	}
	.line{
		width: 150px;
  		height: 2px;
  		margin: 0 auto 13px;
  		background: -moz-linear-gradient(left,  rgba(26,39,66,0.3) 0%, rgba(54,153,255,0.97) 50%, rgba(29,37,61,0.3) 100%);
		background: -webkit-linear-gradient(left,  rgba(26,39,66,0.3) 0%,rgba(54,153,255,0.97) 50%,rgba(29,37,61,0.3) 100%);
		background: linear-gradient(to right,  rgba(26,39,66,0.3) 0%,rgba(54,153,255,0.97) 50%,rgba(29,37,61,0.3) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#661a2742', endColorstr='#661d253d',GradientType=1 );
	}
	p{
		line-height: 1.63;
		color: #a1c5ea;
	}
}


// tester-section ===============================================
.tester-section{
	padding: 55px 0 64px;
	position: relative;
	z-index: 4;
}

.tester-content{
	padding: 47px 50px 50px;
	background: url("../images/tester-bg.jpg") center no-repeat;
	background-size: cover;
	border-radius: 55px;
	position: relative;
	z-index: 3;
	.line-1{
		position: absolute;
		z-index: 4;
		bottom: 100%;
		left: 50%;
		margin-bottom: 38px;
		width: 13px;
		margin-left: 20px;
		height: auto;
	}
	.line-2{
		position: absolute;
		z-index: 4;
		top: 100%;
		left: 18%;
		width: 16px;
		margin-top: 22px;
		height: auto;
	}
}

.tester-bg{
	position: absolute;
	z-index: -1;
	border-radius: 55px;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	&:after{
		content: "";
  		position: absolute;
  		z-index: 3;
  		left: 0;
  		top: 0;
  		height: 100%;
  		width: 100%;
  		background: url("../images/tester-lines.png") center right no-repeat;		
	}
	.circle{
		width: 496px;
		height: 496px;
		top: 50%;
		right: 79px;
		margin-top: -258px;
		background: url("../images/tester-circle.png") center no-repeat;
		position: absolute;
		background-size: 100% auto;
	}
}

.tester-text{
	max-width: 450px;
	.section-title{
		span{
			font-weight: 300;
		}
	}
	h2{
		margin-bottom: 25px;
	}
}

.tester-man{
	position: absolute;
	z-index: 5;
	bottom: 6px;
	right: 140px;
	img{
		max-width: 100%;
		height: auto;
		image-rendering: -webkit-optimize-contrast;
	}
}


// footer ===============================================
footer{
	background: #1f1e2e;
	.statistics-block{
		border-bottom: 1px solid #151521;
		padding: 11px 0;
	}
}

.footer-content{
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	padding: 29px 0 1px;
	margin: 0 -15px;
}

h3{
	font-size: 30px;
  	font-weight: bold;
  	font-family: @bebas;
  	margin-bottom: 17px;
  	text-transform: uppercase;
}

.footer-block{
	padding: 0 15px;
	width: 25%;
	margin-bottom: 25px;
	.dshost-logo{
		margin-bottom: 7px;
		margin-top: -10px;
	}
	.slogan,
	.copyright{
		line-height: 1.63;
	}
}

.footer-menu{
	line-height: 1.63;
	a{
		transition: @transition;
		&:hover{
			text-decoration: underline;
			color: @blue;
		}
	}
}

.footer-enter{
	display: inline-block;
	margin-bottom: 15px;
	background: #3a3851;
	padding: 8px 21px;
	position: relative;
	z-index: 2;
	border-radius: 8px;
	color: #fff;
	&:before{
		content: "";
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: -1;
		background: #2a71b8;
		background: -moz-linear-gradient(left,  #2a71b8 0%, #3b9bfe 100%);
		background: -webkit-linear-gradient(left,  #2a71b8 0%,#3b9bfe 100%);
		background: linear-gradient(to right,  #2a71b8 0%,#3b9bfe 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2a71b8', endColorstr='#3b9bfe',GradientType=1 );
		border-radius: 8px;
		transition: @transition;
		opacity: 0;
	}
	&:hover:before{
		opacity: 1;
	}
	.divider{
		margin: 0 2px;
	}
	a{
		&:hover{
			text-decoration: underline;
		}
	}
}

.footer-contacts{
	line-height: 1.63;
	margin-bottom: 22px;
	h5{
		font-weight: 600;
	}
	a{
		&:hover{
			text-decoration: underline;
		}
	}
}

.footer-pays{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 0 -10px;
	li{
		margin: 0 10px 15px;
	}
	a{
		display: block;
		&:hover i{
			filter: grayscale(0);
			opacity: 1;
		}
	}
	i{
		filter: grayscale(1);
		transition: @transition;
		opacity: 0.3;
	}
}


// all-games-section ============================================
.all-games-section{
	padding: 32px 0 30px;
}

.all-games-list{
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;
	li{
		width: 33.333%;
		padding: 0 10px;
		margin-bottom: 20px;
	}
}

.all-games-block{
	height: 100%;
	border-radius: 15px;
	padding: 16px 20px 21px;
	position: relative;
	background: #1c1c2d;
	z-index: 3;
	overflow: hidden;
	display: block;
	&:after{
		content: "";
		position: absolute;
		z-index: -1;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background: -moz-linear-gradient(top,  rgba(4,49,95,0.56) 0%, rgba(10,61,114,0.56) 12%, rgba(54,153,255,0) 100%);
		background: -webkit-linear-gradient(top,  rgba(4,49,95,0.56) 0%,rgba(10,61,114,0.56) 12%,rgba(54,153,255,0) 100%);
		background: linear-gradient(to bottom,  rgba(4,49,95,0.56) 0%,rgba(10,61,114,0.56) 12%,rgba(54,153,255,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8f04315f', endColorstr='#003699ff',GradientType=0 );
		opacity: 0.5;
		transition: @transition;
	}
	&:hover{
		&:after{
			opacity: 0;
		}
		.image{
			opacity: 0.2;
		}
	}
	&.title-block{
		text-align: center;
		&:after{
			opacity: 1 !important;
		}
		.name{
			color: @blue;
		}
	}
	.image{
		position: absolute;
		z-index: -2;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		opacity: 0.1;
		transition: @transition;
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			image-rendering: -webkit-optimize-contrast;
		}
	}
	.name{
		font-size: 36px;
  		font-weight: bold;
  		color: #fff;
  		font-family: @bebas;
  		white-space: nowrap;
  		overflow: hidden;
  		text-overflow: ellipsis;
  		margin-bottom: 2px;
  		line-height: 1.2;
	}
	.count{
		color: #a1c5ea;
		line-height: 1.2;
	}
	strong{
		font-weight: 600;
	}
}


// monitoring-section ============================================
.monitoring-section{
	text-align: center;
	padding: 27px 0 79px;
}

.monitoring-list-outer{
	position: relative;
	z-index: 2;
	&:after{
		content: "";
		bottom: 0;
		left: -50px;
		position: absolute;
		z-index: 10;
		width: ~"calc(100% + 100px)";
		height: 250px;
		background: -moz-linear-gradient(top,  rgba(21,21,33,0) 0%, rgba(21,21,33,1) 92%, rgba(21,21,33,1) 100%);
		background: -webkit-linear-gradient(top,  rgba(21,21,33,0) 0%,rgba(21,21,33,1) 92%,rgba(21,21,33,1) 100%);
		background: linear-gradient(to bottom,  rgba(21,21,33,0) 0%,rgba(21,21,33,1) 92%,rgba(21,21,33,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00151521', endColorstr='#151521',GradientType=0 );
		pointer-events: none;
	}
	&.visible{
		&:after{
			display: none;
		}
	}
}

.monitoring-list{
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;
}

.monitoring-block{
	width: ~"calc(25% - 20px)";
	margin: 0 10px 20px;
	position: relative;
	height: 230px;
	z-index: 2;
	background: #333333;
	display: block;
	border-radius: 30px;
	text-align: center;
	color: #fff;
	transition: @transition;
	text-align: left;
	&:hover{
		&:after{
			opacity: 0.8;
		}
		.image{
			opacity: 0.5 !important;
		}
		.servers span{
			background: rgba(54, 153, 255, 0.7);
		}
	}
	&:after{
		content: "";
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: -2;
		border-radius: 30px;
		transition: @transition;
		background: -moz-linear-gradient(left,  rgba(4,49,95,0.56) 0%, rgba(10,61,114,0.56) 12%, rgba(54,153,255,0) 100%);
		background: -webkit-linear-gradient(left,  rgba(4,49,95,0.56) 0%,rgba(10,61,114,0.56) 12%,rgba(54,153,255,0) 100%);
		background: linear-gradient(to right,  rgba(4,49,95,0.56) 0%,rgba(10,61,114,0.56) 12%,rgba(54,153,255,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8f04315f', endColorstr='#003699ff',GradientType=1 );
	}
	.image{
		position: absolute;
		z-index: -3;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 30px;
		display: block;
		overflow: hidden;
		opacity: 0.1;
		transition: @transition;
		img{
			width: 100%;
			height: 100%;
			max-width: 100%;
			max-height: 100%;
			object-fit: cover;
			image-rendering: -webkit-optimize-contrast;
			border-radius: 30px;
		}
	}
	.name{
		position: relative;
		z-index: 5;
		padding: 18px 20px;
		font-size: 30px;
		font-weight: 700;
		margin: 0 -4px;
		text-align: center;
		font-family: @bebas;
		margin-bottom: 15px;
		transition: @transition;
  		span{
  			display: block;
  			white-space: nowrap;
	  		overflow: hidden;
	  		text-overflow: ellipsis;
  		}
	}
	.bg{
		left: -1px;
		top: 0;
		height: 100%;
		width: ~"calc(100% + 1px)";
		position: absolute;
		z-index: -1;
		box-shadow: 0px 13px 7px 0 rgba(0, 0, 0, 0.14);
		background: #2c5889;
		transform: rotate(-1.8deg);
		backface-visibility: hidden;
		&:before, 
		&:after{
		   content: '';
		   position: absolute;
		   border-style: solid;
		   border-color: transparent;
		   bottom: -7px;
		}
		&:before{
			border-width: 0 4px 8px 0;
			border-right-color: #1b79dc;
			left: 0;
		}
		&:after{
			border-width: 0 0 8px 5px;
			border-left-color: #1b79dc;
			right: 0;
		}
	}
	.monitoring-top{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 20px 16px;
	}
	.screen{
		flex-shrink: 0;
		border-radius: 12px;
		background: rgba(54, 153, 255, 0.21);
		overflow: hidden;
		width: 69px;
		height: 69px;
		img{
			width: 100%;
			height: 100%;
			max-width: 100%;
			max-height: 100%;
			object-fit: cover;
			image-rendering: -webkit-optimize-contrast;
			border-radius: 12px;
		}
	}
	.servers{
		width: ~"calc(100% - 69px)";
		padding-right: 15px;
		color: #fea900;
		li{
			margin-bottom: 9px;
			&:last-child{
				margin-bottom: 0;
			}
		}
		span{
			display: inline-block;
			white-space: nowrap;
	  		overflow: hidden;
	  		text-overflow: ellipsis;
	  		background: rgba(54, 153, 255, 0.21);
	  		border-radius: 14.5px;
	  		padding: 7px 9px;
	  		max-width: 100%;
	  		transition: @transition;
		}
	}
	.address{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 20px 0 30px;
	}
	.flag{
		flex-shrink: 0;
		width: 22px;
		img{
			height: auto;
			display: block;
			width: 100%;
		}
	}
	.numbers{
		width: ~"calc(100% - 22px)";
		padding-right: 20px;
		white-space: nowrap;
	  	overflow: hidden;
	  	text-overflow: ellipsis;
	  	color: @blue;
	  	line-height: 1.2;
	}
}


// game-about-section ============================================
.game-about-section{
	padding: 33px 0 40px;
	position: relative;
	z-index: 4;
}

.game-about-text{
	max-width: 535px;
	padding: 12px 0;
	position: relative;
	z-index: 5;
	h2{
		margin-bottom: 10px;
	}
}

.tester-man-2{
	position: absolute;
	z-index: 4;
	bottom: 8px;
	right: 80px;
	img{
		max-width: 100%;
		height: auto;
		image-rendering: -webkit-optimize-contrast;
	}
}


// game-hosting-section ============================================
.game-hosting-section{
	padding: 42px 0 23px;
	h2{
		text-align: center;
		margin-bottom: 25px;
	}
}

.game-hosting-content{
	display: flex;
	margin: 0 -10px;
	.title{
		font-size: 36px;
  		font-weight: bold;
  		font-family: @bebas;
  		text-transform: uppercase;
  		margin-bottom: 21px;
  		color: @blue;
	}
}

.game-hosting-calculation{
	margin: 0 10px 20px;
	width: ~"calc(50% - 20px)";
	border-radius: 30px;
  	background: #1c1c2d;
  	padding: 27px 30px 30px;
}


.game-hosting-description{
	margin: 0 10px 20px;
	width: ~"calc(50% - 20px)";
	border-radius: 30px;
	background: rgba(28, 28, 45, 0.29);
	padding: 27px 30px 30px;
	color: #fefefe;
	p{
		margin-bottom: 26px;
		line-height: 1.63;
		&:last-child{
			margin-bottom: 0;
		}
	}
}

.game-hosting-list{
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;
	padding-right: 24px;
	li{
		width: 33.333%;
		padding: 0 15px;
		margin-bottom: 18px;
	}
	.image{
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		background: #1c1c2d;
		width: 80px;
		height: 80px;
		position: relative;
		z-index: 2;
		border-radius: 12px;
		margin-bottom: 14px;
		&:before{
	  		content: "";
	  		position: absolute;
	  		z-index: -1;
	  		border-radius: 12px;
	  		left: 0;
	  		top: 0;
	  		height: 100%;
	  		width: 100%;
	  		background: -moz-linear-gradient(left, rgba(4,49,95,0.56) 0%, rgba(7,55,105,0.56) 6%, rgba(54,153,255,0) 100%);
			background: -webkit-linear-gradient(left, rgba(4,49,95,0.56) 0%,rgba(7,55,105,0.56) 6%,rgba(54,153,255,0) 100%);
			background: linear-gradient(to right, rgba(4,49,95,0.56) 0%,rgba(7,55,105,0.56) 6%,rgba(54,153,255,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8f04315f', endColorstr='#003699ff',GradientType=1 );
	  	}
	}
	strong{
		display: block;
		line-height: 1.63;
		font-weight: 600;
		margin-bottom: 4px;
	}
	p{
		font-weight: 300;
		line-height: 1.63;
		margin-bottom: 0;
	}
}

.game-hosting-form{
	.options-flex{
		display: flex;
		padding-top: 3px;
		margin: 0 -10px;
	}
	.options-block{
		width: 50%;
		padding: 0 10px;
		margin-bottom: 22px;
	}
	.options-title{
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 27px;
	}
	h5{
		font-weight: 300;
		line-height: 1.63;
	}
	.slot-count{
		flex-shrink: 0;
		margin-left: 10px;
		width: 80px;
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 0 10px;
		font-size: 14px;
		border-radius: 17.5px;
  		background: #282849;
  		border: none !important;
	}
	.bottom-flex{
		display: flex;
		align-items: center;
		margin: 0 -10px -20px;
		li{
			width: 50%;
			padding: 0 10px 20px;
		}
	}
	.btn{
		width: 100%;
		font-size: 16px;
		border-radius: 9px;
		&:hover{
			background: #1f5cae;
		}
	}
	.price{
		strong{
			font-size: 24px;
			color: #fea900;
		}
	}
	.rub{
		display: inline-block;
		width: 14px;
		height: 17px;
		background: url("../images/rub-orange.svg") center no-repeat;
		background-size: auto 100%;
	}
}

.range-outer{
	position: relative;
	padding-top: 13px;
	.range-input{
	    appearance: none;
	    width: 100%;
	    height: 5px;
	    border-radius: 2.5px;  
	    outline: none;
	    opacity: 1;
	    margin: 0;
	    border: none !important;
	    padding: 0;
	    background: #282849;
	}
	.range-input::-moz-range-track{
	    appearance: none;
	    width: 100%;
	    height: 5px;
	    border-radius: 2.5px;  
	    outline: none;
	    opacity: 1;
	    margin: 0;
	    border: none !important;
	    padding: 0;
	    background: #282849;
	}
	.range-input::-webkit-slider-thumb {
	    appearance: none;
	    width: 8px;
	    height: 8px;
	    border-radius: 50%; 
	    background: #4582d4;
	    cursor: pointer;
	    border: none;
	    box-shadow: 0px 0px 0px 3px rgba(69, 130, 212, 0.45);
	}
	.range-input::-moz-range-thumb {
		appearance: none;
	    width: 8px;
	    height: 8px;
	    border-radius: 50%;
	    background: #4582d4;
	    cursor: pointer;
	    border: none;
	    box-shadow: 0px 0px 0px 3px rgba(69, 130, 212, 0.45);
	}
	.numbers{
		position: absolute;
		font-size: 12px;
  		font-weight: 300;
  		z-index: 3;
  		bottom: 20px;
	}
	.range-min{
		left: 0;
	}
	.range-max{
		right: 0;
	}
}

.term-radio-list{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 -8px -15px;
	li{
		padding: 0 8px;
		margin-bottom: 15px;
	}
}

.term-radio-block{
  text-align: left;
  font-size: 14px;
  input{
    display: none;
    &:checked{
      & ~ i{
        border-color: @blue;
        background: @blue;
        box-shadow: 0px 0px 0px 4px rgba(69, 130, 212, 0.45);
      }
    }
  }
  label{
    display: inline-flex;
   	align-items: center;
    cursor: pointer;
    line-height: 1.1;
    &:hover i{
      border-color: @blue;
    }
  }
  i{
    flex-shrink: 0;
    position: relative;
    transition: @transition;
    display: block;
    width: 12px;
    height: 12px;
    border: none;
    border-radius: 50%;
    background: transparent;
    border: 1px solid #2e2e50;
    margin-right: 12px;
  }
}


// game-pay-section ============================================
.game-pay-section{
	padding: 35px 0 23px;
	h2{
		text-align: center;
	}
}

.game-pay-list{
	display: flex;
	flex-wrap: wrap;
	margin: -11px -10px 0;
	justify-content: center;
	& > li{
		padding: 0 10px;
		margin-bottom: 20px;
		width: 25%;
	}
	&.slots-list{
		& > li{
			flex-grow: 1;
			max-width: 400px;
		}
	}
}

.game-pay-block{
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
	z-index: 2;
	background: #1c1c2d;
	border-radius: 20px;
	text-align: center;
	color: #fff;
	transition: @transition;
	text-align: center;
	padding: 11px 20px 20px;
	&:hover{
		&:after{
			opacity: 0.65 !important;
		}
	}
	&:after{
		content: "";
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: -2;
		border-radius: 20px;
		opacity: 1;
		opacity: 0.33;
		transition: @transition;
		background: -moz-linear-gradient(top,  rgba(4,49,95,0.7) 0%, rgba(10,61,114,0.7) 16%, rgba(54,153,255,0) 100%);
		background: -webkit-linear-gradient(top,  rgba(4,49,95,0.7) 0%,rgba(10,61,114,0.7) 16%,rgba(54,153,255,0) 100%);
		background: linear-gradient(to bottom,  rgba(4,49,95,0.7) 0%,rgba(10,61,114,0.7) 16%,rgba(54,153,255,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8f04315f', endColorstr='#003699ff',GradientType=0 );
	}
	&.slogan{
		&:after{
			opacity: 1;
		}
		.image{
			opacity: 0.15;
		}
		.name{
			color: #fff;
			padding: 10px 10px 0;
			margin-bottom: 2px;
		}
	}
	.image{
		position: absolute;
		z-index: -3;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 20px;
		display: block;
		overflow: hidden;
		opacity: 0.02;
		transition: @transition;
		img{
			width: 100%;
			height: 100%;
			max-width: 100%;
			max-height: 100%;
			object-fit: cover;
			image-rendering: -webkit-optimize-contrast;
			border-radius: 20px;
		}
	}
	.name{
		color: @blue;
		font-family: @bebas;
		font-size: 36px;
		font-weight: bold;
		line-height: 1.28;
		margin-bottom: 8px;
	}
	.line{
		width: 160px;
  		height: 2px;
  		margin: 0 auto 6px;
  		background: -moz-linear-gradient(left,  rgba(26,39,66,0.3) 0%, rgba(54,153,255,0.97) 50%, rgba(29,37,61,0.3) 100%);
		background: -webkit-linear-gradient(left,  rgba(26,39,66,0.3) 0%,rgba(54,153,255,0.97) 50%,rgba(29,37,61,0.3) 100%);
		background: linear-gradient(to right,  rgba(26,39,66,0.3) 0%,rgba(54,153,255,0.97) 50%,rgba(29,37,61,0.3) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#661a2742', endColorstr='#661d253d',GradientType=1 );
	}
	.btn{
		margin: auto auto 0;
		font-size: 16px;
		font-weight: 700;
		border-radius: 9px;
		width: 100%;
		max-width: 240px;
		padding: 12px 20px;
		&:hover{
			background: #1f5cae;
		}
	}
	.name-big{
		color: @blue;
		font-family: @bebas;
		font-size: 60px;
		font-weight: bold;
		margin-bottom: 25px;
	}
}

.game-pay-info{
	margin: 0 -20px 26px;
	text-align: left;
	li{
		padding: 11px 20px;
		&:nth-of-type(even){
			background: rgba(69, 130, 212, 0.08);
		}
	}
	.detail{
		display: flex;
		align-items: center;
		justify-content: space-between;
		line-height: 1.25;
	}
	.param{
		color: #a1c5ea;
		max-width: 55%;
		padding-right: 10px;
	}
	.value{
		color: #fff;
		text-align: right;
		min-width: 45%;
	}
	.rub{
		display: inline-block;
		width: 14px;
		height: 17px;
		background: url("../images/rub-orange.svg") center no-repeat;
		background-size: auto 100%;
	}
	.price{
		font-size: 24px;
		color: #fea900;
		white-space: nowrap;
	}
	.old-price{
		color: #354576;
		text-decoration: line-through;
		margin-right: 6px;
	}
	.promokod{
		margin-top: 2px;
		line-height: 1.25;
		color: #3699ff;
		font-size: 14px;
		strong{
			font-weight: 600;
		}
	}
}


//game-slider-section ======================================
.game-slider-section{
	text-align: center;
	padding: 28px 0 80px;
	h2{
		margin-bottom: 24px;
	}
}

.game-slider-outer{
	position: relative;
}

.game-slider-naw{
	position: absolute;
	z-index: 5;
	top: 50%;
	margin-top: -12px;
	cursor: pointer;
	&:hover i{
		filter: none !important;
		opacity: 1;
	}
	&.prev{
		left: -100px;
	}
	&.next{
		right: -100px;
	}
	i{
		transition: @transition;
		filter: brightness(0) invert(1);
	}
}

.game-slider{
	display: flex;
	align-items: center;
	margin: 0 -10px;
}

.game-slider-block{
	border-radius: 15px;
	margin: 0 10px;
	width: ~"calc(25% - 20px)";
	flex-shrink: 0;
	background: #181826;
	display: block;
	img{
		width: 100%;
		height: auto;
		image-rendering: -webkit-optimize-contrast;
		border-radius: 15px;
		display: block;
		transition: @transition;
		opacity: 0.16;
	}
	&.active-slide{
		img{
			opacity: 1;
		}
	}
}




/*=========================MEDIA=========================*/
@media(max-width:1239px){
	.about-image{
		width: 450px;
	}
	.tariffs-block{
		width: ~"calc(33.333% - 20px)";
	}
	.why-me-list .name{
		font-size: 32px;
	}
	.tester-man{
		right: 40px;
	}
	.tester-man-2{
		right: 30px;
	}
	.tester-bg .circle{
		right: -14px;
	}
	.qualities-list .name{
		font-size: 30px;
	}
	.tariffs-section{
		padding-top: 60px;
	}
	.monitoring-block{
		width: ~"calc(33.333% - 20px)";
	}
	.game-about-text{
		padding: 0;
		max-width: 462px;
	}
	.game-hosting-list{
		padding-right: 0;
	}
	.game-pay-list > li{
		width: 33.333%;
	}
	.game-slider-naw{
		&.prev{
			left: -40px;
		}
		&.next{
			right: -40px;
		}
	}
}



@media(min-width:1020px){
	.header-options{
		display: flex !important;
	}
}
@media(max-width:1019px){
	html{
		font-size: 15px;
	}
	.mobile-buter{
		display: block;
		cursor: pointer;
		span{
			width: 30px;
			height: 3px;
			background: @blue;
			border-radius: 2px;
			display: block;
			margin: 6px auto;
			transition: @transition;
			position: relative;
			&:nth-of-type(2){
				transition: none;
			}
		}
		&.open-buter span{
			&:nth-of-type(1){
				top: 9px;
				transform: rotate(45deg);
			}
			&:nth-of-type(2){
				opacity: 0;
			}
			&:nth-of-type(3){
				top: -9px;
				transform: rotate(-45deg);
			}
		}
	}
	.header-options{
		display: none;
		position: absolute;
		top: 100%;
		right: 0;
		padding: 25px;
		background: #1f1f33;
		box-shadow: 0 0 40px 3.1px rgba(0, 0, 0, 0.6);
		width: 300px;
		font-size: 16px;
	}
	.header-menu{
		display: block;
		margin: 0;
		text-align: left;
		margin-bottom: 30px;
		li{
			margin: 0 0 20px;
		}
		.active{
			font-weight: 600;
			color: @blue;
			&:after{
				height: 100%;
				left: -10px;
				top: 0;
				margin-top: 0;
				background: @blue;
				width: 3px;
			}
		}
	}
	.header-enter{
		margin: 0;
	}
	header{
		.statistics-block{
			display: none;
		}
	}
	.header-content{
		padding: 15px 0;
	}
	.about-image{
		width: 400px;
		margin-right: -60px;
	}
	.about-image-bg{
		width: 540px;
	  	height: 540px;
		margin-top: -270px;
		margin-left: -270px;
		li{
			&:nth-of-type(2){
				width: 460px;
	  			height: 460px;
				margin-left: -230px;
				margin-top: -230px;
			}
			&:nth-of-type(3){
				width: 380px;
	  			height: 380px;
				margin-left: -190px;
				margin-top: -190px;
			}
		}
	}
	.about-text{
		margin-right: 60px;
	}
	.about-section{
		padding: 70px 0 60px;
	}
	.tariffs-block{
		width: ~"calc(50% - 20px)";
	}
	h2{
		font-size: 60px;
	}
	.why-me-list li{
		width: 33.333%;
	}
	.tester-content{
		padding: 50px 35px;
	}
	.tester-text{
		max-width: 340px;
	}
	.tester-man{
		right: 10px;
	}
	.tester-bg .circle{
		right: -40px;
	}
	.section-title{
		line-height: 1.2;
		font-size: 20px;
	}
	.qualities-title{
		font-size: 20px;
		line-height: 1.2;
	}
	.qualities-list li{
		width: 50%;
	}
	.footer-block{
		width: 50%;
		&.with-menu{
			order: 2;
		}	
	}
	.footer-menu{
		li{
			margin-bottom: 4px;
		}
	}
	.white-theme{
		.header-options{
			background: #fff;
			box-shadow: 0 0 40px 2px rgba(0, 0, 0, 0.15);
		}
	}
	.all-games-list li{
		width: 50%;
	}
	.all-games-block .name{
		font-size: 32px;
	}
	.monitoring-block{
		width: ~"calc(50% - 20px)";
	}
	.game-about-section{
		padding-top: 45px;
	}
	.game-about-text{
		max-width: 310px;
	}
	.tester-man-2{
		right: -30px;
	}
	.game-hosting-content{
		flex-wrap: wrap;
	}
	.game-hosting-calculation{
		width: ~"calc(100% - 20px)";
	}
	.game-hosting-description{
		width: ~"calc(100% - 20px)";
	}
	.game-pay-list > li{
		width: 50%;
	}
	.game-slider-block{
		width: ~"calc(33.333% - 20px)";
	}
}




@media(max-width:767px){
	html{
		font-size: 14px;
	}
	.section-title{
		justify-content: center;	
	  	&:after{
	  		content: "";
	  		margin-left: 21px;
	  		display: block;
	  		background: #a1c5ea;
	  		width: 17px;
	  		height: 2px;
	  		flex-shrink: 0;
	  	}
	  	&.long:before,
	  	&.long:after{
	  		width: 17px;
	  	}
	}
	.qualities-title{
		&:before,
	  	&:after{
	  		width: 17px;
	  	}
	}
	.about-section{
		padding: 35px 0;
		background: none;
	}
	.about-image{
		display: none;
	}
	.about-text{
		max-width: 100%;
		margin-right: 0;
		text-align: center;
	}
	h2{
		text-align: center;
		font-size: 50px;
	}
	.tariffs-top{
		display: block;
		text-align: center;
		h2{
			max-width: 100%;
			margin-bottom: 30px;
		}
	}
	.tariffs-block{
		max-width: 280px;
		height: 240px;
		margin-bottom: 15px;
		.name{
			font-size: 30px;
			height: 105px;
		}
		.price{
			font-size: 17px;
		}
	}
	.why-me-list{
		text-align: center;
		.image{
			margin-left: auto;
			margin-right: auto;
		}
		.name{
			font-size: 28px;
		}
	}
	.qualities-list .name{
		font-size: 28px;
	}
	.qualities-section{
		padding: 35px 0 20px;
	}
	.statistics-content{
		display: block;
		text-align: center;
	}
	.statistics-count{
		justify-content: center;
		font-size: 15px;
		margin-bottom: 20px;
	}
	.statistics-theme{
		justify-content: center;
	}
	.tester-man,
	.tester-man-2{
		display: none;
	}
	.tester-bg .circle{
		right: 50%;
		margin-right: -248px;
	}
	.tester-text{
		text-align: center;
		max-width: 100%;
		margin-right: 0;
	}
	.tester-section{
		padding: 25px 0 35px;
	}
	.tariffs-list{
		justify-content: center;
	}
	.footer-menu{
		li{
			margin-bottom: 6px;
		}
	}
	.monitoring-list{
		justify-content: center;
	}
	.monitoring-block{
		max-width: 280px;
		margin-bottom: 15px;
		.name{
			font-size: 28px;
		}
	}
	.all-games-list{
		margin: 0 -5px;
		li{
			margin-bottom: 10px;
			padding: 0 5px;
		}
	}
	.all-games-block{
		padding: 15px;
		.name{
			font-size: 28px;
		}
	}
	.game-about-text{
		max-width: 100%;
		text-align: center;
	}
	.game-about-section{
		padding: 35px 0 30px;
	}
	.game-pay-block .name-big{
		font-size: 55px;
	}
	.game-slider-outer{
		margin: 0 25px;
	}
	.game-slider-block{
		width: ~"calc(50% - 20px)";
	}
	.game-slider-naw{
		&.prev{
			left: -26px;
		}
		&.next{
			right: -26px;
		}
	}
}




@media(max-width:500px){
	h2{
		font-size: 42px;
	}
	.tariffs-block{
		width: ~"calc(100% - 20px)";
	}
	.why-me-list{
		margin: 0 -10px;
		li{
			width: 50%;
			padding: 0 10px;
			margin-bottom: 25px;
		}
		.name{
			font-size: 24px;
		}
	}
	.tester-content{
		padding: 40px 25px;
	}
	.btn{
		font-size: 18px;
		padding-left: 35px;
		padding-right: 35px;
		&.yellow-btn{
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	.section-title{
		font-size: 18px;
	}
	.qualities-title{
		font-size: 18px;
	}
	.qualities-list{
		.name{
			font-size: 24px;
		}
		.line{
			width: 120px;
		}
	}
	.statistics-count{
		display: block;
		li{
			margin-bottom: 15px;
		}
	}
	.footer-content{
		text-align: center;
	}
	.footer-block{
		width: 100%;
		.dshost-logo{
			margin: 0 auto 25px;
		}
	}
	.footer-pays{
		justify-content: center;
	}
	.tariffs-section{
		padding-top: 40px;
	}
	.header-options{
		width: 100%;
	}
	.tester-bg{
		.circle{
			width: 248px;
			height: 248px;
			margin-right: -124px;
			margin-top: -124px;
		}
	}
	.monitoring-block{
		width: ~"calc(100% - 20px)";
	}
	.all-games-list{
		li{
			width: 100%;
		}
	}
	.all-games-block{
		text-align: center;
		padding: 12px 15px;
	}
	.game-hosting-form{
		.options-flex{
			flex-wrap: wrap;
		}
		.options-block{
			width: 100%;
		}
		.bottom-flex{
			flex-wrap: wrap;
			text-align: center;
			li{
				width: 100%;
			}
		}
	}
	.game-hosting-calculation{
		padding: 25px;
		border-radius: 25px;
	}
	.game-hosting-description{
		padding: 25px;
		border-radius: 25px;
	}
	.game-hosting-list{
		margin-bottom: 8px;
		li{
			width: 100%;
			display: flex;
			align-items: center;
		}
		.image{
			flex-shrink: 0;
			margin-right: 16px;
			margin-bottom: 0;
		}
	}
	.game-pay-list > li{
		width: 100%;
		flex-grow: 1;
		max-width: 400px;
	}
	.game-slider-block{
		width: ~"calc(100% - 20px)";
	}
	.monitoring-block{
		&:nth-of-type(n+5){
			display: none;
		}
	}
}

















/*===================СВЕТЛАЯ ТЕМА====================*/
.white-theme{
	color: #333;
	background: #fff;
	.dshost-logo{
		background: url("../images/logo-light.png") center no-repeat;
		background-size: 100% auto;
	}
	.statistics-block{
		background: #f9f9f9;
	}
	.icon-moon{
		&:extend(.icon-moon_gray);
	}
	.icon-sun_black{
		&:extend(.icon-sun);
	}
	.statistics-theme-toggle{
		border-color: #e3dfdf;
	}
	.about-image-bg li{
		background: @blue;
	}
	.btn.empty-btn{
		background: @blue;
	}
	.tariffs-block{
		background: @blue;
		&:hover{
			background: #333333;
		}
		&:after{
			
		}
	}
	.why-me-list{
		.image{
			background: @blue;
		}
		p{
			color: #333;
		}
	}
	.icon-why_me_1{
		&:extend(.icon-why_me_1_white);
	}
	.icon-why_me_2{
		&:extend(.icon-why_me_2_white);
	}
	.icon-why_me_3{
		&:extend(.icon-why_me_3_white);
	}
	.icon-why_me_4{
		&:extend(.icon-why_me_4_white);
	}
	.icon-why_me_5{
		&:extend(.icon-why_me_5_white);
	}
	.icon-why_me_6{
		&:extend(.icon-why_me_6_white);
	}
	.icon-why_me_7{
		&:extend(.icon-why_me_7_white);
	}
	.icon-why_me_8{
		&:extend(.icon-why_me_8_white);
	}
	.icon-why_me_9{
		&:extend(.icon-why_me_9_white);
	}
	.icon-why_me_circle{
		&:extend(.icon-why_me_circle_white);
	}
	.icon-why_me_fly{
		&:extend(.icon-why_me_fly_white);
	}
	.qualities-list{
		.image{
			background: @blue;
		}
		.line{
	  		background: -moz-linear-gradient(left, rgba(26,39,66,0) 0%, rgba(54,153,255,1) 50%, rgba(26,39,66,0) 100%);
			background: -webkit-linear-gradient(left, rgba(26,39,66,0) 0%,rgba(54,153,255,1) 50%,rgba(26,39,66,0) 100%);
			background: linear-gradient(to right, rgba(26,39,66,0) 0%,rgba(54,153,255,1) 50%,rgba(26,39,66,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001a2742', endColorstr='#001a2742',GradientType=1 );
		}
		p{
			color: #333;
		}
	}
	.icon-qualities_1{
		&:extend(.icon-qualities_1_white);
	}
	.icon-qualities_2{
		&:extend(.icon-qualities_2_white);
	}
	.icon-qualities_3{
		&:extend(.icon-qualities_3_white);
	}
	.icon-qualities_4{
		&:extend(.icon-qualities_4_white);
	}
	.tester-content{
		background: #c9dcf2;
		.section-title{
			color: #6c90c4;
			&:before{
				background: #6c90c4;
			}
			&:after{
				background: #6c90c4;
			}
		}
	}
	.tester-bg{
		.circle{
			background: url("../images/tester-circle-white.png") center no-repeat;
			background-size: 100% auto;
		}
	}
	footer{
		background: #f9f9f9;
		.statistics-block{
			border-bottom-color: #eae9e9;
		}
	}
	.footer-menu{
		color: #777;
	}
	.footer-enter{
		background: @blue;
	}
	.footer-pays{
		i{
			filter: grayscale(0);
			opacity: 0.35;
		}
	}
	.all-games-block{
		background: @blue;
		&.title-block{
			.name{
				color: #fff;
			}
		}
		.count{
			color: #fff;
		}
	}
	.monitoring-list-outer{
		&:after{
			background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 92%, rgba(255,255,255,1) 100%);
			background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 92%,rgba(255,255,255,1) 100%);
			background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 92%,rgba(255,255,255,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
		}
	}
	.monitoring-block{
		background: @blue;
		.bg{
			background: @blue;
		}
		.screen{
			background: #2c5889;
		}
		.numbers{
			color: #fff;
		}
		.image{
			opacity: 0.21;
		}
	}
	.game-hosting-calculation{
		background: rgba(54, 153, 255, 0.1);
	}
	.game-hosting-description{
		background: #f9fcff;
		color: #333;
	}
	.game-hosting-list .image{
		background: @blue;
		&:before{
			background: -moz-linear-gradient(left,  rgba(0,0,0,0.42) 0%, rgba(255,255,255,0) 100%);
			background: -webkit-linear-gradient(left,  rgba(0,0,0,0.42) 0%,rgba(255,255,255,0) 100%);
			background: linear-gradient(to right,  rgba(0,0,0,0.42) 0%,rgba(255,255,255,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6b000000', endColorstr='#00ffffff',GradientType=1 );
		}
	}
	.icon-hosting_1{
		&:extend(.icon-hosting_1_white);
	}
	.icon-hosting_2{
		&:extend(.icon-hosting_2_white);
	}
	.icon-hosting_3{
		&:extend(.icon-hosting_3_white);
	}
	.simple-form{
		input,
		select,
		textarea{
			color: #333;
			border: 1px solid #bfd8f2;
			&::-webkit-input-placeholder{ 
			   	color: #333;
			    opacity: 1;
			}
			&:-moz-placeholder{ 
			   	color: #333;
			    opacity: 1;
			}
			&::-moz-placeholder{ 
			  	color: #333;
			    opacity: 1;
			}
			&:-ms-input-placeholder{ 
			   	color: #333;
			    opacity: 1;
			}
		}
		option{
			color: #333;
			background: #fff;
		}
	}
	.game-hosting-form{
		.slot-count{
			background: #d0e8ff;
		}
	}
	.term-radio-block{
	  input{
	    &:checked{
	      & ~ i{
	        border-color: #4582d4;
	        background: #4582d4;
	      }
	    }
	  }
	  i{
	    border: 1px solid #bfd8f2;
	  }
	}
	.range-outer{
		.range-input{
		    background: #d0e8ff;
		}
		.range-input::-moz-range-track{
		    background: #d0e8ff;
		}
	}
	.game-slider-naw{
		i{
			filter: grayscale(1);
			opacity: 0.82;
		}
	}
	.game-slider-block{
		background: #fff;
	}
	.game-pay-block{
		background: @blue;
		&:after{
			opacity: 1;
			background: -moz-linear-gradient(top,  rgba(54,153,255,0) 0%, rgba(10,61,114,1) 88%, rgba(4,49,95,1) 100%);
			background: -webkit-linear-gradient(top,  rgba(54,153,255,0) 0%,rgba(10,61,114,1) 88%,rgba(4,49,95,1) 100%);
			background: linear-gradient(to bottom,  rgba(54,153,255,0) 0%,rgba(10,61,114,1) 88%,rgba(4,49,95,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003699ff', endColorstr='#04315f',GradientType=0 );
		}
		&.slogan{
			background: #13148e;
			&:after{
				background: -moz-linear-gradient(top,  rgba(4,49,95,0.7) 0%, rgba(10,61,114,0.7) 16%, rgba(54,153,255,0) 100%);
				background: -webkit-linear-gradient(top,  rgba(4,49,95,0.7) 0%,rgba(10,61,114,0.7) 16%,rgba(54,153,255,0) 100%);
				background: linear-gradient(to bottom,  rgba(4,49,95,0.7) 0%,rgba(10,61,114,0.7) 16%,rgba(54,153,255,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8f04315f', endColorstr='#003699ff',GradientType=0 );
			}
			.image{
				opacity: 0.15;
			}
		}
		.line{
			background: -moz-linear-gradient(left,  rgba(54,153,255,0) 0%, rgba(255,255,255,1) 50%, rgba(54,153,255,0) 100%);
			background: -webkit-linear-gradient(left,  rgba(54,153,255,0) 0%,rgba(255,255,255,1) 50%,rgba(54,153,255,0) 100%);
			background: linear-gradient(to right,  rgba(54,153,255,0) 0%,rgba(255,255,255,1) 50%,rgba(54,153,255,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003699ff', endColorstr='#003699ff',GradientType=1 );
		}
		.image{
			opacity: 0.27;
		}
		.name{
			color: #fff;
		}
		.btn:hover{
			background: #3c79cb;
		}
	}
	.game-pay-info{
		li{
			&:nth-of-type(even){
				background: rgba(19, 20, 142, 0.26);
			}
		}
		.param{
			color: #fff;
		}
		.value{
			font-weight: 700;
		}
		.old-price{
			color: #fff;
		}
	}
}
